import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import { useScreenRecognition } from 'utils/hooks';
import UmbracoImage from 'common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { SimpleContestPageProps } from './models';
import './SimpleContestPage.scss';

const SimpleContestPage: FC<SimpleContestPageProps> = ({
  data: {
    umbracoSimpleContest: {
      langProps: { urls, lang },
      seoProps: {
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      },
      bannerDesktopImage,
      bannerDesktopAltText,
      bannerMobileImage,
      bannerMobileAltText,
      bannerAdditionalText,
      title,
      content,
    },
  },
}) => {
  const screenResolution = useScreenRecognition();

  return (
    <Layout langProps={{ urls, lang }} className="contest-page">
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <UmbracoImage
        image={screenResolution.isXS ? bannerMobileImage : bannerDesktopImage}
        alt={screenResolution.isXS ? bannerMobileAltText : bannerDesktopAltText}
        key={screenResolution.isXS ? bannerMobileImage.fallbackUrl : bannerDesktopImage.fallbackUrl}
        objectFit="contain"
        className="simple-contest__top-banner"
      />
      {bannerAdditionalText ? (
        <div className="simple-contest__banner-text-container">
          <DangerouslySetInnerHtml
            className="simple-contest__banner-text"
            html={bannerAdditionalText}
          />
        </div>
      ) : null}
      <div className="simple-contest__container">
        {title ? (
          <DangerouslySetInnerHtml className="simple-contest__title" element="h1" html={title} />
        ) : null}
        {content ? (
          <DangerouslySetInnerHtml className="simple-contest__content" html={content} />
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SimpleContestQuery($pageId: String, $lang: String) {
    umbracoSimpleContest(id: { eq: $pageId }, langProps: { lang: { eq: $lang } }) {
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      seoProps {
        seoMetaTitle
        seoMetaKeywords
        seoMetaDescription
        seoExternalHreflangs {
          key
          value
        }
        ogImage
      }
      bannerDesktopImage {
        ...umbracoImage
      }
      bannerDesktopAltText
      bannerMobileImage {
        ...umbracoImage
      }
      bannerMobileAltText
      bannerAdditionalText
      title
      content
    }
  }
`;

export default SimpleContestPage;
